const initState = () => {
  const userData = localStorage.getItem('userData')
  if (userData) {
    const {
      userEmail, firstName, lastName, role, photo,
    } = JSON.parse(userData)
    return {
      userEmail, firstName, lastName, role, photo,
    }
  }
  return {
    userEmail: '',
    firstName: '',
    lastName: '',
    role: '',
    photo: '',
  }
}

export default {
  namespaced: true,
  state: initState(),
  getters: {
    fullName: state => {
      if (state.firstName && state.lastName) {
        return `${state.firstName} ${state.lastName}`
      }
      return state.firstName || state.lastName
    },
    photo: state => state.photo,
  },
  mutations: {
    UPDATE_EMAIL(state, userEmail) {
      state.userEmail = userEmail
    },
    UPDATE_FIRST_NAME(state, firstName) {
      state.firstName = firstName
      let userData = JSON.parse(localStorage.getItem('userData'))
      userData = {
        ...userData,
        firstName,
      }
      localStorage.setItem('userData', JSON.stringify(userData))
    },
    UPDATE_LAST_NAME(state, lastName) {
      state.lastName = lastName
      let userData = JSON.parse(localStorage.getItem('userData'))
      userData = {
        ...userData,
        lastName,
      }
      localStorage.setItem('userData', JSON.stringify(userData))
    },
    UPDATE_PHOTO(state, photo) {
      state.photo = photo
      let userData = JSON.parse(localStorage.getItem('userData'))
      userData = {
        ...userData,
        photo,
      }
      localStorage.setItem('userData', JSON.stringify(userData))
    },
  },
  actions: {
  },
}
