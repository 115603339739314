import Vue from 'vue'
import {
  ModalPlugin,
  ToastPlugin,
  TooltipPlugin,
  VBTogglePlugin,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueExcelEditor from 'vue-excel-editor'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/acl'

// Axios Mock Adapter
// import '@/idleaf_fake_db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VBTogglePlugin)
Vue.use(TooltipPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Excel Editor
Vue.use(VueExcelEditor)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
