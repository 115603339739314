export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'view',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Anonymous',
      action: 'view',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Anonymous',
      action: 'view',
    },
  },
  {
    path: '/reset-password/:uid/:token',
    name: 'auth-reset-password',
    component: () => import('@/views/pages/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Anonymous',
      action: 'view',
    },
  },

  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'view',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'view',
    },
  },
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
      resource: 'Anonymous',
      action: 'view',
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'view',
    },
  },
  {
    path: '/pages/account-setting',
    name: 'pages-account-setting',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Account Settings',
          active: true,
        },
      ],
      resource: 'Auth',
      action: 'view',
    },
  },
  {
    path: '/pages/clients',
    name: 'pages-clients',
    component: () => import('@/views/pages/clients/Clients.vue'),
    meta: {
      pageTitle: 'Clients',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Clients',
          active: true,
        },
      ],
      resource: 'Clients',
      action: 'manage',
    },
  },
  {
    path: '/pages/brands',
    name: 'pages-brands',
    component: () => import('@/views/pages/brands/Brands.vue'),
    meta: {
      pageTitle: 'Brands',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Brands',
          active: true,
        },
      ],
      resource: 'Brand',
      action: 'manage',
    },
  },
  {
    path: '/pages/collections',
    name: 'pages-collections',
    component: () => import('@/views/pages/collections/Collections.vue'),
    meta: {
      pageTitle: 'Collections',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Collections',
          active: true,
        },
      ],
      resource: 'Collection',
      action: 'manage',
    },
  },
]
