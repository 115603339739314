import Vue from 'vue'

// axios
import axios from 'axios'

let baseURL = 'http://127.0.0.1:8000'
if (process.env.NODE_ENV === 'production') {
  baseURL = 'https://api.idleaf.pt'
}

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL,
  withCredentials: true,
  xsrfHeaderName: 'X-CSRFToken',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
